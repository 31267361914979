<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Perfil</h2>

    <v-row class="mt-4">
      <v-col sm="4" md="3">
        <v-card>
          <div class="text-center pa-5">
            <v-tooltip top
              ><template v-slot:activator="{ on, attrs }"
                ><v-badge bordered color="deep-purple accent-4" icon="mdi-pencil" overlap
                  ><v-avatar size="140" v-bind="attrs" @click="changeAvatarPic" v-on="on">
                    <img id="thumbnail" :src="thumbnail" />
                  </v-avatar>
                </v-badge>
              </template>
              <span>Clique na foto para trocar sua foto de perfil</span>
            </v-tooltip>

            <h4 class="mt-4">{{ userInfo.firstName }} {{ userInfo.lastName }}</h4>
          </div>

          <v-list class="pb-5">
            <v-list-item-group color="primary">
              <v-list-item v-for="item in items" :key="item.title" :to="item.path">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col sm="8" md="9">
        <vue-page-transition name="fade-in-up">
          <router-view />
        </vue-page-transition>
      </v-col>
    </v-row>
    <change-avatar ref="modalChangeAvatar" :usuario="userInfo" />
  </v-main>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    changeAvatar: () => import('./components/changeAvatar.vue'),
  },

  data() {
    return {
      picture: '',
      items: [
        { title: 'Dados de acesso', icon: 'mdi-account-box', path: '/profile/edit' },
        { title: 'Redefinir Senha', icon: 'mdi-lock', path: '/profile/change-password' },
        { title: 'Autenticação (2FA)', icon: 'mdi-shield-check', path: '/profile/authentication' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      thumbnail: 'user/getUserThumbnail',
    }),
  },

  methods: {
    ...mapActions('user', ['saveUser', 'changeAvatar', 'changeModal']),

    changeAvatarPic() {
      this.changeModal(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-navigation-drawer {
  background-color: inherit !important;
  height: inherit;
}
</style>
