var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Perfil"),
      ]),
      _c(
        "v-row",
        { staticClass: "mt-4" },
        [
          _c(
            "v-col",
            { attrs: { sm: "4", md: "3" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "div",
                    { staticClass: "text-center pa-5" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        bordered: "",
                                        color: "deep-purple accent-4",
                                        icon: "mdi-pencil",
                                        overlap: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: { size: "140" },
                                              on: {
                                                click: _vm.changeAvatarPic,
                                              },
                                            },
                                            "v-avatar",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("img", {
                                            attrs: {
                                              id: "thumbnail",
                                              src: _vm.thumbnail,
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Clique na foto para trocar sua foto de perfil"
                            ),
                          ]),
                        ]
                      ),
                      _c("h4", { staticClass: "mt-4" }, [
                        _vm._v(
                          _vm._s(_vm.userInfo.firstName) +
                            " " +
                            _vm._s(_vm.userInfo.lastName)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list",
                    { staticClass: "pb-5" },
                    [
                      _c(
                        "v-list-item-group",
                        { attrs: { color: "primary" } },
                        _vm._l(_vm.items, function (item) {
                          return _c(
                            "v-list-item",
                            { key: item.title, attrs: { to: item.path } },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { sm: "8", md: "9" } },
            [
              _c(
                "vue-page-transition",
                { attrs: { name: "fade-in-up" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("change-avatar", {
        ref: "modalChangeAvatar",
        attrs: { usuario: _vm.userInfo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }